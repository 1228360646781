$section-main-padding : $vert-space*1.5 0 !default;

body {
  main {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 0;
  }
  main.content-rich {
    padding: $section-main-padding;
  }
	@media (max-width:719px) {
    main {
			aside {
				&#sidebar-first { order: 1; }
				&#sidebar-second { order: 3; }
			}
			section#content { order: 2; }
		}
	}
	
  &.no-sidebars {
    main {
      section#content {
        width: calc(100% - #{$gutt});
				margin: 0 calc(#{$gutt} / 2) $vert-space;
      }
      
    }
  }

  &.one-sidebar.sidebar-first {
    main {
      .primary {
        width:100%;
      }
      .outer-wrapper {display:flex;flex-wrap:wrap;}
      aside {
        &#sidebar-first {
          width: calc(100% - #{$gutt});
          margin: 0 calc(#{$gutt} / 2) $vert-space;
          @include media($narrow) {
            width: calc(100%/12*3 - #{$gutt});
          }
          @include media($normal) {
            width: calc(100%/12*3 - #{$gutt});
            margin-top:16px;
          }
				}
      }

      section#content {
        width: calc(100% - #{$gutt});
        margin: 0 calc(#{$gutt} / 2) $vert-space;
        @include media($narrow) {
          width: calc(100%/12*9 - #{$gutt});
        }
        @include media($normal) {
          width: calc(100%/12*9 - #{$gutt});
        }
			}
     } 
  }

  &.one-sidebar.sidebar-second {
    main {
      .primary {
        width:100%;
      }
      .outer-wrapper {display:flex;flex-wrap:wrap;} 
      aside {
        &#sidebar-second {
          width: calc(100% - #{$gutt});
          margin: calc(#{$gutt} / 2);
          @include media($narrow) {
            width: calc(100%/4 -  #{$gutt});
          }
          @include media ($normal) {
            margin-top:16px;
          }
        }
      }

      section#content {
        width: calc(100% - #{$gutt});
        margin: 0 calc(#{$gutt} / 2) $vert-space;
        @include media($narrow) {
          width: calc(100%/4*3 -  #{$gutt});
        }
      }
    }
  }

  &.two-sidebars { 
    main {
      .primary {
        width:100%;
      }
      .outer-wrapper {display:flex;flex-wrap:wrap;}
      aside {
        &#sidebar-first {
          width: 100%;
          margin: 0 calc(#{$gutt} / 2) $vert-space;
          @include media($narrow) {
            width: calc(100%/4 - #{$gutt});
          }
          @include media($normal) {
            width: calc(100%/4 - #{$gutt});
            margin-top:16px;
          }
				}
        &#sidebar-second {
          width: 100%;
          margin: 0 calc(#{$gutt} / 2) $vert-space;
					@media (min-width:$narrow) and (max-width:959px){
						margin: 0 calc(#{$gutt} / 2) $vert-space calc(100%/12*2 + #{$gutt}/2);
					}
          @include media($normal) {
            width: calc(100%/4 - #{$gutt});
            margin-top:16px;
          }
        }
			}

      section#content {
        width: calc(100% - #{$gutt});
        margin: 0 calc(#{$gutt} / 2) $vert-space;
        @include media($narrow) {
          width: calc(100%/12*9 - #{$gutt});
        }
        @include media($normal) {
          width: calc(100%/12*6 - #{$gutt});
        }
			}
    }
  }	
}

// SIDEBAR MARGINS FOR NODES WITH HERO FULL-WIDTH IMAGE

body.page-hero-image {
  &.one-sidebar.sidebar-first {
    main aside#sidebar-first {
      @include media($normal) {
        margin-top:$vert-space*2;
      }
    }
  }

  &.one-sidebar.sidebar-second {
    main aside#sidebar-second {
      @include media($normal) {
        margin-top:$vert-space*2;
      }
    }
  }

  &.two-sidebars {
    main aside {
      &#sidebar-first {
        @include media($normal) {
          margin-top:$vert-space*2;
        }
      }
      &#sidebar-second {
        @include media($normal) {
          margin-top:$vert-space*2;
        }
      }
    }
  }	
}