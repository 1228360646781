$card-text-padding : $vert-space $horz-space !default;
$card-margin-bottom : $vert-space !default;

.card {
	box-sizing:border-box;
	display:block;
  margin-bottom:$card-margin-bottom;
	overflow:hidden;
	position:relative;

}
.card-text {
	box-sizing:border-box;
	display:block;
	overflow:hidden;
	padding:$card-text-padding;
	
	:last-child {
		margin-bottom:0;
	}
}
.card-body {
	*:last-child {
		margin-bottom:0;
	}
}
.card-image,
.card-video,
.card-photo,
.card-logo {
	display:block;
	line-height:0;
}
.card-image,
.card-photo,
.card-logo {
  text-align:center;
}
.card-video {
	margin-bottom:15px;
	
	span.ext {
		display:none;
	}
}

.node-type-publication {
	.card-journal,
	.card-citation-date,
	.card-volume,
	.card-pages {
		display: inline;
		
		div,
		div::after {
			display: inline;
		}
	}
	
	.card-journal {
		font-weight: 600;
		div::after {
			content:",";
			margin: 0 0px 0 2px;	
		}
	}
	.card-citation-date,
	.card-year {
		font-weight: 600;
		div::after {
    	content: ";";
		}
	}
	.card-volume {
		div::after {
			content:":";
			margin: 0 0px 0 2px;	
		}
	}

	.card-doi,
	.card-pmid {
		margin-top: 5px;
		
		.card-label {
			display: inline-block;
		}
	}
	
	.card-authors,
	.card-abbr-authors {
		font-style:italic;
		margin-bottom:$vert-space/4;
	}
	
	.card-link {
		margin-bottom: 5px;
	}
}